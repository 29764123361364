import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  Button, FormControl, FormHelperText,
  Grid, InputLabel, OutlinedInput,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
    maxWidth: "400px",
    width: "90%"
  },
  spacing: {
    /*
        paddingTop: theme.spacing(10)
    */
  }
}));

const PlatformField = ({
                         display,
                         slideId,
                         setActive,
                         index,
                         id,
                         name,
                         control,
                         defaultValue,
                         label,
                         rules,
                         inputProps,
                         InputProps,
                         addSpacing,
                         prompt,
                         placeholder,
                         ...other
                       }) => {
  const classes = useStyles();

  const { clearErrors, formState: { errors } } = useFormContext();

  //const setActive = useContext(ScrollerContext);
  return (
    <FormControl sx={{ display: display ? "block" : "none" }}>
      <InputLabel shrink={true} htmlFor="component-outlined">{label}</InputLabel>
      <Controller
        id={id}
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <div>
          <OutlinedInput className={classes.input}
                         label={label}
                         placeholder={placeholder}
                         variant="filled"
                         notched={true}
                         value={value || ""}
                         onChange={(e) => {
                           onChange(e);
                           clearErrors();
                         }}
                         error={!!error}
                         inputProps={inputProps}
                         inputRef={ref}
                         onFocus={(e) => {
                           /*console.log("SLIDE ID: " + slideId);
                           if (!!error) {
                             console.log(error);
                             setActive(slideId);
                           }*/
                         }}
                         onBlur={(e) => {
                           //clearErrors();
                           onBlur(e);
                         }}
                         {...other}
          />
            {!!error && (
              <FormHelperText error  id={`platforms.${id}.error`}>
                {error.message}
              </FormHelperText>)}
          </div>
        )}
        rules={rules}
      />


    </FormControl>
  );
};

export { PlatformField };