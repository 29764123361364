import { InputAdornment } from "@mui/material";
import { EmailOutlined, LanguageOutlined, PasswordOutlined } from "@mui/icons-material";
import { validate } from "email-validator";

const isValidFile = async (file) => {
  if (file) {
    try {
      console.log(file)
    }
    catch (err) {
      return "Invalid File";
    }
  }
}

const isValidURL = async (url) => {
  if (url) {
    try {
      let uriS = "https://";
      let uri = "http://";

      if (url.length > 200 ) {
        return "Max length 200 characters";
      }
      if (!url.startsWith(uri) && !url.startsWith(uriS)) {
        url = uriS + url;
      }

      const result = await new URL(url);
      if (!result) {
        return "Invalid URL";
      }
    } catch (err) {
      return "Invalid URL";
    }
  }
};

const isValidPhone = (phone) => {
  try {
    const PHONE_REGEX =
      new RegExp("^(\\+|\\d)[0-9]{9,16}$");

    return PHONE_REGEX.test(phone) ? true : "Invalid Phone Number ";
  } catch (err) {
    return "Invalid Phone Number";
  }
};

// TODO: Add MX/SMTP check if getting fake emails.
const isValidEmail = async (email) => {
  const invalidMsg = "Invalid email address";
  try {
    if (validate(email)) {
      return await fetch(`https://open.kickbox.com/v1/disposable/${email}`)
        .then((response) => response.json())
        .then((data) => {
          const disposable = data["disposable"];
          if (disposable === undefined) {
            console.log(
              "This shouldn't happen -> log it. Skipping disposable email check."
            );
            return true;
          } else if (disposable === false) {
            return true;
          } else {
            return invalidMsg;
          }
        });
    } else {
      return invalidMsg;
    }
  } catch (e) {
    return invalidMsg;
  }
};

/*const isValidPassword = async (password) => {
  const invalidMsg = "Invalid password";

}*/

const company = {
  id: "company",
  name: "company",
  defaultValue: "",
  rules: { required: "Agency / Brand required", maxLength: { value: 255, message: "Max length of 255 characters" } },
  label: "Agency / Brand",
  inputProps: {
    autoComplete: "no-autocomplete",
  },
  prompt: "Agency / Brand",
  type: "company"
};

const fullname = {
  id: "fullName",
  name: "fullName",
  defaultValue: "",
  rules: { required: "Full name required", maxLength: { value: 255, message: "Max length of 255 characters" } },
  label: "Full Name",
  prompt: "Your name",
  type: "name",
  inputProps: {
    autoComplete: "no-autocomplete",
  }
};

const email = {
  id: "email",
  name: "email",
  defaultValue: "",
  rules: { required: "Email required", validate: isValidEmail, maxLength: { value: 200, message: "Max length of 200 characters" } },
  label: "Email",
  InputProps: {
    startAdornment: (
      <InputAdornment position="start">
        <EmailOutlined />
      </InputAdornment>
    )
  },
  prompt: "Your email address",
  type: "email"

};

//TODO: make a seperate field for login and register
const password = {
  id: "password",
  name: "password",
  defaultValue: "",
  rules: {
    required: "Password required",
    minLength: { value: 10, message: "Password must be 10 - 64 characters" },
    maxLength: { value: 64, message: "Password must be 10 - 64 characters" }
  },
  label: "Password",
  inputProps: {
    autoComplete: "password",
  }

};

const confirm = {
  id: "confirm",
  name: "confirm",
  defaultValue: "",
  rules: { required: "Confirm password required" },
  label: "Confirm",
  inputProps: {},
};

//TODO: make a seperate field for login and register
const passwordOptional = {
  id: "passwordOptional",
  name: "passwordOptional",
  defaultValue: "",
  rules: {
    minLength: { value: 10, message: "Password must be 10 - 64 characters" },
    maxLength: { value: 64, message: "Password must be 10 - 64 characters" }
  },
  label: "Password",
  inputProps: {
    autoComplete: "password",
  }

};

const confirmOptional = {
  id: "confirmOptional",
  name: "confirmOptional",
  defaultValue: "",
  label: "Confirm",
  inputProps: {}
};


const phone = {
  id: "phone",
  name: "phone",
  defaultValue: "",
  placeholder: "1234567890",
  rules: { required: "Phone required", validate: isValidPhone },
  label: "Phone",
  prompt: "Phone",
  type: "tel",
  inputProps: {
    autoComplete: "no-autocomplete",
  }
};

const file = {
  id: "file",
  name: "file",
  defaultValue: "",
  rules:  {validate: isValidFile },
  label: "File",
  prompt: "File",
  type: "file",
  inputProps: {
    autoComplete: "no-autocomplete",
  }
};

const keywordText = {
  id: "keywordText",
  name: "keywordText",
  defaultValue: "",
  rules: { required: "Keyword Text required" },
  label: "Keywords",
  placeholder: "Type or cut and paste your keywords here",
  type: "text",
  multiline: true,
  rows: 6,
  InputLabelProps: { shrink: true },
  inputProps: {},
};

const keywordUrl = {
  id: "keywordUrl",
  name: "keywordUrl",
  defaultValue: "",
  rules: { validate: isValidURL },
  label: "Keyword URL",
  placeholder: "https:// (Max 200 char.)",
  type: "text",
  InputLabelProps: { shrink: true },
  inputProps: {},
};

const audienceName = {
  id: "audienceName",
  name: "audienceName",
  defaultValue: "",
  rules: { required: "Audience Name required", maxLength: { value: 200, message: "Max length of 200 characters" } },
  label: "Audience Name",
  placeholder: "ex. Ad Agency - Hotel Chain (Max 200 char.)",
  InputLabelProps: { shrink: true },
  inputProps: {},
};

const locationName = {
  id: "locationName",
  name: "locationName",
  defaultValue: "",
  rules: { required: "Location Name required" , maxLength: { value: 255, message: "Max length of 255 characters" }},
  label: "Location Name (Max 255 char.)",
  placeholder: "ex. US",
  InputLabelProps: { shrink: true },
  inputProps: {},
};

const comments = {
  id: "comments",
  name: "comments",
  defaultValue: "",
  rules: {},
  placeholder: "Questions/Comments",
  type: "text",
  multiline: true,
  rows: 10,
  InputLabelProps: { shrink: true },
  inputProps: {},
};

const other = {
  id: "platforms.Other.request",
  name: "platforms.Other.request",
  defaultValue: "",
  rules: {maxLength: { value: 200, message: "Max length of 200 characters" }},
  label: "DSP request",
  type: "text",
  InputLabelProps: { shrink: true },
  inputProps: {
    autoComplete: "no-autocomplete",
  }
};

const segment = {
  id: "segments",
  name: "segments",
  defaultValue: "",
  //rules: { required: "At least one audience is required", validate: minimumSegments },
  label: "Audiences",
};

/*
const recaptcha = {

}*/


export {
  company,
  fullname,
  email,
  password,
  phone,
  confirm,
  file,
  passwordOptional,
  confirmOptional,
  keywordUrl,
  audienceName,
  keywordText,
  comments,
  other,
  segment,
  locationName
};